export interface INavbarItem {
  key: string;
  title: string;
  separator?: boolean;
  items?: INavbarItem[]
  icon?: string;
}

/** MENU */
const awardNavbarItem: INavbarItem = {
  key: "section-prizes",
  title: "views.menu.premi",
  icon: "starhollow",
  items: [
    {
      key: "prizes",
      title: "views.menu.elenco_premi",
      items: []
    },
    {
      key: "prizes/categories", //TODO:
      title: "views.menu.categorie_premi",
      items: []
    },
    {
      key: "prizes/order",
      title: "views.menu.ordinamento_premi",
      items: []
    }
  ]
}
const emailTemplateNavbarItem: INavbarItem = {
  key: "section-email-templates",
  title: "views.menu.email_template",
  icon: "email",
  items: [
    {
      key: "email-templates",
      title: "views.menu.lista_email_template",
      items: []
    }
  ]
}

const userNavbarItem: INavbarItem = {
  key: "section-users",
  title: "views.menu.utenti",
  icon: "users",
  items: [
    {
      key: "users",
      title: "views.menu.elenco_utenti",
      items: []
    },
    {
      key: "users/categories",
      title: "views.menu.categorie_utenti",
      items: []
    },
    {
      key: "levels",
      title: "views.menu.livelli_utenti",
      items: []
    },
    {
      key: "users/referral-requests",
      title: "views.menu.referral_requests_utenti",
      items: []
    }
  ]
}
const earningRuleNavbarItem: INavbarItem = {
  key: "section-earning-rules",
  title: "views.menu.regole",
  icon: "transfer",
  items: [
    {
      key: "earning-rules",
      title: "views.menu.elenco_regole",
      items: []
    }
  ]
}
const productNavbarItem: INavbarItem = {
  key: "section-products",
  title: "views.menu.products",
  icon: "box",
  items: [
    {
      key: "products",
      title: "views.menu.elenco_products",
      items: []
    },
    {
      key: "products/product-family",
      title: "views.menu.famiglie_prodotti",
      items: []
    },
    {
      key: "products/product-subfamily",
      title: "views.menu.subfamiglie_prodotti",
      items: []
    }
  ]
}
export const menu: INavbarItem[] = [
  userNavbarItem, earningRuleNavbarItem, awardNavbarItem, emailTemplateNavbarItem, productNavbarItem
]


/** SETTINGS */
export const settings: INavbarItem[] = [
  {
    key: "settings-global",
    title: "views.menu.settings_global",
    icon: "cog",
    items: [
      {
        title: "views.menu.settings_global_fo",
        key: "/settings/general/frontoffice"
      },
      {
        title: "views.menu.settings_global_bo",
        key: "/settings/general/backoffice"
      }
    ]
  },
  {
    key: "settings-admin",
    icon: "shield",
    title: "views.menu.settings_admin",
    items: [
      {
        title: "views.menu.settings_admin_elenco",
        key: "/settings/admin/users"
      },
      {
        title: "views.menu.settings_admin_first_access",
        key: "/settings/admin/first-access"
      },
    ]
  },
  {
    key: "referral",
    icon: "shield",
    title: "views.menu.settings_referral",
    items: [
      {
        title: "views.menu.settings_referral_elenco",
        key: "/settings/referral/points"
      },
    ]
  },
  {
    key: "server_smtp",
    icon: "email",
    title: "views.menu.settings_email_smtp",
    items: [
      {
        title: "views.menu.settings_email_smtp_config",
        key: "/settings/e-mail/settings"
      },
      {
        title: "views.menu.settings_email_test",
        key: "/settings/e-mail/test-send-mail"
      },
    ]
  },
  {
    key: "integrations",
    icon: "transfer",
    title: "views.menu.integrations",
    items: [
      {
        title: "views.menu.integrations_all",
        key: "/settings/integrations/all"
      },
      {
        title: "views.menu.validation_order",
        key: "/settings/integrations/validation-orders"
      },
    ]
  }
]