import React, { lazy, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { Button, Input } from "@diemmea/dma-design-system";
import Fallback from "../../Fallback";
import { useTranslation } from "react-i18next";
import { HEIGHT_MENU } from "../../../services/utils";
import { settings } from "../../../services/menu";


const SettingsNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="hidden md:block w-72 bg-gray-200 pl-12 pt-8" style={{ minHeight: `calc(100vh - ${HEIGHT_MENU}px)` }}>
      <h1 className="font-bold text-l">{t("views.settings.title")}</h1>
      <div>
        {
          settings.map((settingItem, indexSettingItem) => {
            return (
              <div key={indexSettingItem} className="mt-8">
                <h2 className="font-semibold text-base">{t(settingItem.title)}</h2>
                <div className="flex flex-col">
                  {
                    settingItem.items?.map((subItem, indexSubItem) => {
                      return (
                        <span onClick={() => {
                          subItem?.key && navigate(subItem?.key)
                        }} className="mt-2 cursor-pointer" key={indexSubItem}>{t(subItem.title)}</span>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const SettingsLayout = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <SettingsNavbar />
      <div className="bg-white w-full px-4 pt-8">
        <Outlet />
      </div>
    </div>
  );
};




function SettingsRouter() {
  const SettingsGeneralRouter = lazy(() => import("./General/router"));
  const SettingsAdminRouter = lazy(() => import("./Admin/router"));
  const ReferralRouter = lazy(() => import("./Referral/router"));
  const EmailRouter = lazy(() => import("./Email/router"));
  const IntegrationsRouter = lazy(() => import("./Integration/router"));

  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Fallback />}>
              <SettingsGeneralRouter />
            </Suspense>
          }
        />
        {/* General */}
        <Route
          path="general/*"
          element={
            <Suspense fallback={<Fallback />}>
              <SettingsGeneralRouter />
            </Suspense>
          }
        />
        {/* General */}
        <Route
          path="admin/*"
          element={
            <Suspense fallback={<Fallback />}>
              <SettingsAdminRouter />
            </Suspense>
          }
        />
        {/* Referral */}
        <Route
          path="referral/*"
          element={
            <Suspense fallback={<Fallback />}>
              <ReferralRouter />
            </Suspense>
          }
        />
        {/* E-mail */}
        <Route
          path="e-mail/*"
          element={
            <Suspense fallback={<Fallback />}>
              <EmailRouter />
            </Suspense>
          }
        />
        {/* Integrations */}
        <Route
          path="integrations/*"
          element={
            <Suspense fallback={<Fallback />}>
              <IntegrationsRouter />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
export default SettingsRouter;
