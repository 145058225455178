import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './localization/i18n';
import reportWebVitals from './reportWebVitals';
import Fallback from './views/Fallback';
import { Provider } from "react-redux";
import { store } from './redux/store';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <Suspense fallback={<Fallback/>}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
        <ToastContainer autoClose={2000} />
      </Provider>
    </React.StrictMode>
  </Suspense>
,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
