import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { toast } from "react-toastify";

// Define a type for the slice state
interface GeneralState {
  route: string
}

// Define the initial state using that type
const initialState: GeneralState = {
  route: "",
}

interface INotification {
  message: string;
  type: "error" | "warning" | "success";
}




export const generalSlice = createSlice({
  name: 'general',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<INotification>) => {
      const message = action.payload.message;
      if(action.payload.type === "error"){
        toast.error(message);
      }else if(action.payload.type === "warning"){
        toast.warn(message);
      }else if(action.payload.type === "success"){
        toast.success(message);
      }
    },
  }
})

export const { showMessage } = generalSlice.actions

export default generalSlice.reducer