import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Button, Input } from "@diemmea/dma-design-system";
import Fallback from "../Fallback";
import { useTranslation } from 'react-i18next';
import Image from "../../components/Image";
import logo from "./../../assets/images/logo-loyaltyone-color.svg"

const AuthLayout = (props:any) => {
  const { t } = useTranslation();
  return (
    <div
      className="h-full container mx-auto pt-24 pb-8 flex flex-col justify-between px-8 md:px-0"
      style={{ maxWidth: 445 }}
    >

      <div>
        <div className="flex justify-center">
          <a href="#" className="mb-8">
            <Image
              alt="Logo loyaltyone"
              src={logo}
              classNameImage="w-64"
              classNameWrapper="w-64 mb-16"
            />
          </a>
        </div>
        <Outlet />
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-xxs">{`©${new Date().getFullYear()} DMA, Inc. ${t('footer.rights_reserved')}.`}</p>
        <Button
          isLink
          onClick={() => {
            //TODO: go to privacy
          }}
        >
          {t('footer.privacy')}
        </Button>
      </div>
    </div>
  );
};

const AuthPage = () => {
    const Login = lazy(() => import("./Login"));
    const ForgotPassword = lazy(() => import("./ForgotPassword"));
    return(
        <Routes>
            <Route element={<AuthLayout />}>
            <Route path="login" element={
                <Suspense fallback={<Fallback/>}>
                    <Login />
                </Suspense>
            } />
            <Route path="forgot-password" element={
                <Suspense fallback={<Fallback/>}>
                    <ForgotPassword/>
                </Suspense>
            } />
            <Route index  element={
                <Suspense fallback={<Fallback/>}>
                    <Login />
                </Suspense>
            } />
            </Route>
        </Routes>
    )
};

export default AuthPage;
