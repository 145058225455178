import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Utente from '../../model/Utente.model'

// Define a type for the slice state
interface UserState {
  page: number,
  currentUser: Utente | undefined
}

// Define the initial state using that type
const initialState: UserState = {
  page: 0,
  currentUser: undefined
}

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload
    },
    setCurrentUser: (state, action: PayloadAction<Utente|undefined>) => {
      state.currentUser = action.payload
  },
  }
})

export const { setCurrentPage, setCurrentUser } = userSlice.actions

export default userSlice.reducer