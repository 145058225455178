import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

// Define a type for the slice state
interface AwardState {
  page: number
}

// Define the initial state using that type
const initialState: AwardState = {
  page: 0,
}

export const awardSlice = createSlice({
  name: 'award',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload
    },
  }
})

export const { setCurrentPage } = awardSlice.actions

export default awardSlice.reducer