import React, { useEffect } from "react";
import RouterApp from "./router/AppRouter";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { getEmail } from "./services/storage";

function App() {
  useEffect(()=>{
    console.log(`<< RUNNING Loyalty with ${process.env.REACT_APP_ENV_NAME} env >>`)
  },[])
  const app_version = require("../package.json").version;
  Bugsnag.start({
    apiKey: "c5807d402bd1a3ece9fc77a7ca388530",
    plugins: [new BugsnagPluginReact()],
    enabledBreadcrumbTypes: [
      "error",
      "log",
      "navigation",
      "request",
      "user",
      "state",
    ],
    releaseStage: process.env.REACT_APP_ENV_NAME,
    enabledReleaseStages: ["staging","prod","demo"],
    appVersion: app_version,
    onError: function (event) {
      console.log("onError", event);
      event.setUser(undefined, getEmail() || undefined, app_version);
    },
  });
  const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

  return (
    <ErrorBoundary>
      <RouterApp />
    </ErrorBoundary>
  );
}

export default App;
