import { configureStore } from '@reduxjs/toolkit'
import generalSlice from './reducers/generalSlice'
import authSlice from './reducers/authSlice'
import categorySlice from './reducers/categorySlice'
import awardSlice from './reducers/awardSlice'
import emailTemplateSlice from './reducers/emailtemplateSlice'
import levelSlice from './reducers/levelSlice'
import userSlice from './reducers/userSlice'
import earningruleSlice from './reducers/earningruleSlice'
import settingsSlice from './reducers/settingsSlice'

export const store = configureStore({
  reducer: {
    general: generalSlice,
    auth: authSlice,
    category: categorySlice,
    award: awardSlice,
    emailtemplate: emailTemplateSlice,
    level: levelSlice,
    user: userSlice,
    earningrule: earningruleSlice,
    settings: settingsSlice
  },
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch