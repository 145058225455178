import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AuthPage from "../../views/Auth";
import Private from "../../views/Private";
import Amplify from "aws-amplify";
import { useAppSelector } from "../../redux/hooks";

// const Fallback = <div>...</div>; //TODO:
// const Login = lazy(() => import("../../views/Login"));

// Init Cognito
Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
});

export default function RouterApp() {
  const is_logged = useAppSelector((state) => state.auth.is_logged)
  return (
    <Router>
      <Routes>
          {is_logged ? (
            <>
              <Route path='/*' element={<Private />} />
              <Route index element={<Navigate to='/users' />} />
              <Route path='auth/*' element={<Navigate to='/users' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
      </Routes>
    </Router>
  );
}
