import "./index.css";
import { FC, useState } from "react";

interface IFallback{
    title?: string;
    className?: string;
  }

const Fallback: FC<IFallback> = ({ title, className, ...props }: IFallback) => {
    return (
    <div className={`${className} flex flex-col items-center justify-center h-full py-8 transition-all`}>
           <div className="flex">
                <p className="mr-2"><span className="font-bold text-secondaryDark">loyaltyone</span>.<span>io</span></p>
                <div className="loader4"></div>
           </div>
           {
               title && 
               <p className="mt-16 text-secondaryUltraDark">{title}</p>
           }
    </div>
  );
};
export default Fallback;
