import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface EmailTemplateState {
  page: number
}

// Define the initial state using that type
const initialState: EmailTemplateState = {
  page: 0,
}

export const emailtemplateSlice = createSlice({
  name: 'emailtemplate',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload
    },
  }
})

export const { setCurrentPage } = emailtemplateSlice.actions

export default emailtemplateSlice.reducer