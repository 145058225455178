import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { isUserLogged, resetUserLoggedData, storeUserLoggedData } from '../../services/storage'
import { Auth } from "aws-amplify";
import { CognitoUser, UserData } from 'amazon-cognito-identity-js';
import User from "../../model/User.model";

// Define a type for the slice state
interface AuthState {
  is_logged: boolean
}

// Define the initial state using that type
const initialState: AuthState = {
  is_logged: isUserLogged(),
}

interface IPayloadLogin {
  email: string,
  password: string
}
export const loginUser = createAsyncThunk(
  "users/loginUser",
  async (values: IPayloadLogin, thunkAPI) => {
    try {
      // Login to Cognito AWS
      const responseSignIn:CognitoUser = await Auth.signIn(values.email, values.password)
      // Get User Attribute
      const responseUser:User = await new Promise((resolve, reject) =>{
        responseSignIn.getUserData((error, userData: UserData|undefined)=>{
          if(error) reject(error)
          const user = User.factoryCognitoUser(userData!)
          resolve(user)
        })
      })
      // Store data locally
      storeUserLoggedData(responseSignIn.getSignInUserSession()!, responseUser)
      return responseUser
    } catch (e:any) {
      return thunkAPI.rejectWithValue({error: e})
    }
  }
)

export const logoutUser = createAsyncThunk(
  "users/logoutUser",
  async (values:void, thunkAPI) => {
    try {
      // Logout to Cognito AWS
      await Auth.signOut()
      // Reset data locally
      resetUserLoggedData()
      return false;
    } catch (e:any) {
      console.log(e)
      return thunkAPI.rejectWithValue({error: e})
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
  },
  extraReducers:{
    [`${loginUser.fulfilled}`]: (state:AuthState, user: User) => {
      state.is_logged = true;
      return state;
    },
    [`${loginUser.rejected}`]: (state:any, error: any) => {
      state.is_logged = false;
      throw Error(error?.payload?.error?.message)
    },
    [`${logoutUser.fulfilled}`]: (state:AuthState, user: User) => {
      state.is_logged = false;
      return state;
    },
    [`${logoutUser.rejected}`]: (state:any, error: any) => {
      console.log(error)
      throw Error(error?.payload?.error?.message)
    },
  }
})

// export const { setIsLogged, setIsLoggedOut } = authSlice.actions

export default authSlice.reducer