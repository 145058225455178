import { UserData } from 'amazon-cognito-identity-js';

  export interface IAPIProfile {
    email: string;
    employment: string;
    image_url: string;
    name: string;
    surname: string;
    role: string;
    username: string;
  }
  
  export default class User {
    private _email: string;
    private _name: string;
    private _username: string;
    private _sub: string;
  
    constructor(
      email: string,
      name: string,
      username: string,
      sub: string
    ) {
      this._email = email;
      this._name = name;
      this._username = username;
      this._sub = sub;
    }
  
    get email(): string {
      return this._email;
    }
    get name(): string {
      return this._name;
    }
    get username(): string {
      return this._username;
    }
    get sub(): string{
      return this._sub;
    }
  
    static factoryCognitoUser(data: UserData) {
      return new User(
        data.UserAttributes.find(att => att.Name==="email")?.Value || "",
        data.UserAttributes.find(att => att.Name==="name")?.Value || "",
        data.Username,
        data.UserAttributes.find(att => att.Name==="sub")?.Value || ""
      );
    }

  }
  