import React, { lazy, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Button, Input } from "@diemmea/dma-design-system";
import Fallback from "../Fallback";
import { useTranslation } from "react-i18next";
import "./index.css";
import Navbar from "../../components/Navbar";
import SettingsRouter from "./Settings";

const PrivateLayout = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between flex-col" style={{minHeight:"100vh"}}>
      <div>
        <Navbar />
        <div className="">
          <Outlet/>
        </div>
      </div>
      <div className="bg-secondaryDark h-16">
        <div className="px-12 mx-auto flex w-full h-full items-center md:justify-between justify-center">
          <span className="text-white text-sm md:block hidden">©{new Date().getFullYear()} loyaltyone. All rights reserved</span>
          <span className="text-white text-sm">© Credits: <a className="text-primary" href="https://www.diemmea.com/" target={"_blank"}>diemmea.com</a></span>
        </div>
      </div>
    </div>
  );
};

function Private() {
  const NotFound = lazy(() => import("./../NotFound"));
  const Dashboard = lazy(() => import("./Dashboard"));
  const AwardRouter = lazy(() => import("./Award/router"));
  const EmailTemplateRouter = lazy(() => import("./EmailTemplate/router"));
  const LevelRouter = lazy(() => import("./Level/router"));
  const UserRouter = lazy(() => import("./User/router"));
  const EarningRuleRouter = lazy(() => import("./EarningRule/router"));
  const ProductRouter = lazy(() => import("./Product/router"));


  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        {/* <Route
          path="dashboard"
          element={
            <Suspense fallback={<Fallback />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          index
          element={
            <Suspense fallback={<Fallback />}>
              <Dashboard />
            </Suspense>
          }
        />   */}

        <Route
          index
          element={
            <Suspense fallback={<Fallback />}>
              <UserRouter/>
            </Suspense>
          }
        />  

        {/* User */}
        <Route
          path="users/*"
          element={
            <Suspense fallback={<Fallback />}>
              <UserRouter/>
            </Suspense>
          }
        />  

        {/* Awards */}
        <Route
          path="prizes/*"
          element={
            <Suspense fallback={<Fallback />}>
              <AwardRouter/>
            </Suspense>
          }
        />   
        {/* Email Template */}
        <Route
          path="email-templates/*"
          element={
            <Suspense fallback={<Fallback />}>
              <EmailTemplateRouter/>
            </Suspense>
          }
        />  
        {/* Level */}
        <Route
          path="levels/*"
          element={
            <Suspense fallback={<Fallback />}>
              <LevelRouter/>
            </Suspense>
          }
        />  
        {/* Products */}
        <Route
          path="products/*"
          element={
            <Suspense fallback={<Fallback />}>
              <ProductRouter/>
            </Suspense>
          }
        />   
        {/* Earning Rule */}
        <Route
          path="earning-rules/*"
          element={
            <Suspense fallback={<Fallback />}>
              <EarningRuleRouter/>
            </Suspense>
          }
        />    
        {/* Settings */}
        <Route
          path="settings/*"
          element={
            <Suspense fallback={<Fallback />}>
              <SettingsRouter/>
            </Suspense>
          }
        />   
        <Route
          path="*"
          element={
            <Suspense fallback={<Fallback />}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
export default Private;
