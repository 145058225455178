import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

// Define a type for the slice state
interface CategoryState {
  page: number
}

// Define the initial state using that type
const initialState: CategoryState = {
  page: 0,
}

export const categorySlice = createSlice({
  name: 'category',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload
    },
  }
})

export const { setCurrentPage } = categorySlice.actions

export default categorySlice.reducer