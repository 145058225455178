import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { toast } from "react-toastify";
import { EStepChooseObjectOptions } from '../../views/Private/Settings/Integration/Hubspot/Configuration/StepChooseObject';

export type MappingPropertyHsObject = "Contact"|"Company"|undefined
interface MappingProperty {hs_property:string, loyalty_property:string, hs_object: MappingPropertyHsObject}
interface SettingsState {
    chooseObjectOption: EStepChooseObjectOptions|undefined
    listMappingProperties:MappingProperty[]
    contactListId:string|undefined
}

// Define the initial state using that type
const initialState: SettingsState = {
    chooseObjectOption: undefined,
    listMappingProperties:[],
    contactListId: undefined
}

export const settingsSlice = createSlice({
  name: 'settings',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    editStepChooseObjectOption: (state, action: PayloadAction<{chooseObjectOption: EStepChooseObjectOptions}>) => {
        state.chooseObjectOption = action.payload.chooseObjectOption;
        state.listMappingProperties = [];
    },
    editListMappingProperties: (state, action: PayloadAction<{listMappingProperties: MappingProperty[]}>) => {
        state.listMappingProperties = action.payload.listMappingProperties;
    },
    editContactListIds: (state, action: PayloadAction<{contactListId: string|undefined}>) => {
        state.contactListId = action.payload.contactListId;
    },
  }
})

export const { editStepChooseObjectOption, editListMappingProperties, editContactListIds } = settingsSlice.actions

export default settingsSlice.reducer